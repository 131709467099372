var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business_sub_page"},[_vm._m(0),_c('div',{staticClass:"main_panel"},_vm._l((_vm.corporateCulture),function(cItem,idx){return _c('div',{key:idx,staticClass:"card_item"},[_c('img',{class:[{
        'rotateY_img':cItem.rotateY
      }],attrs:{"src":cItem.pic}}),_c('div',{staticClass:"card_main",class:[
        {
          'card_main_right':idx%2===1
        }
      ]},[_c('div',{staticClass:"card_label"},[_vm._v(" "+_vm._s(cItem.label)+" ")]),_c('div',{staticClass:"card_line"}),_c('div',{staticClass:"card_label_en"},[_vm._v(" "+_vm._s(cItem.labelEn)+" ")]),_vm._l((cItem.text),function(section,sIdx){return _c('div',{key:sIdx,staticClass:"card_section"},[_vm._v(" "+_vm._s(section)+" ")])})],2)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topic_panel"},[_c('div',{staticClass:"topic_desc"},[_vm._v(" 企业文化 ")]),_c('div',{staticClass:"topic_desc_en"},[_vm._v(" CORPORATE CULTURE ")]),_c('div',{staticClass:"topic_desc_line"})])
}]

export { render, staticRenderFns }