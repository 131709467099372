<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        企业文化
      </div>
      <div class="topic_desc_en">
        CORPORATE CULTURE
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="card_item" v-for="(cItem,idx) in corporateCulture" :key="idx">
        <img :class="[{
          'rotateY_img':cItem.rotateY
        }]" :src="cItem.pic">
        <div class="card_main" :class="[
          {
            'card_main_right':idx%2===1
          }
        ]">
          <div class="card_label">
            {{cItem.label}}
          </div>
          <div class="card_line"></div>
          <div class="card_label_en">
            {{cItem.labelEn}}
          </div>
          <div class="card_section" v-for="(section,sIdx) in cItem.text" :key="sIdx">
            {{section}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      corporateCulture:[]
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.getDataCorporateCultures();
    },
    // 获取数据 - 企业文化
    getDataCorporateCultures(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=corporateCulture`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.corporateCulture = configData.map(i=>{
            return Object.assign({},i,{
              pic: `${window.PICSEVERURL}/${i.pic}`
            })
          });
        }else{
          _this.$notify({
            title: '失败',
            message: "企业文化数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 66px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 120px;
    .card_item{
      width: 100%;
      height: 400px;
      position: relative;
      margin-bottom: 57px;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
      &:last-child{
        margin-bottom: 120px;
      }
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        &.rotateY_img{
          transform: rotateY(180deg);
        }
      }
      .card_main{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 66px;
        box-sizing: border-box;
        &.card_main_right{
          align-items: flex-end;
        }
        .card_label{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 28px;
          color: #0086D1;
          line-height: 31px;
        }
        .card_line{
          width: 76px;
          height: 3px;
          background: #0086D1;
          margin-top: 19px;
          margin-bottom: 17px;
        }
        .card_label_en{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #BCBDBE;
          line-height: 28px;
          margin-bottom: 20px;
        }
        .card_section{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 40px;
          color: #0F2836;
          line-height: 60px;
        }
      }
    }
  }
}
</style>